import {makeAutoObservable} from "mobx"
import { nameAppearenсe } from "../consts"

export default class UserStore {

    isAuth = false
    user = {id: 0, name: ''}

    constructor() {
        makeAutoObservable(this)
    }

    setIsAuth(bool) {
        this.isAuth = bool
    }
    
    setUser(user) {
        this.user = user
    }

    get name() {
        return nameAppearenсe(this.user.name)
    }
}