import {makeAutoObservable} from "mobx"

export default class ScopeStore {

   
    constructor() {
        makeAutoObservable(this)
    }

    setDefaults() {
        this.scope = null
    }

    setScope(scope) {
        this.scope = scope
    }
    
}