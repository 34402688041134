import React, { useState, useContext, useEffect } from 'react'
import { observer } from "mobx-react-lite"
import { Context } from './index'
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './components/AppRouter';
import NavBar from './components/NavBar';
import { check } from './components/http/userAPI'
import { Spinner } from "react-bootstrap"

const App = observer(() => {
  const { userStore } = useContext(Context)
  const [loading, setLoading] = useState(true)
  const [connected, setConnected] = useState(true)
  useEffect(() => {
    check().then(data => {
      userStore.setUser(data)
      userStore.setIsAuth(true)
    }).catch(e => {
      if (e.response) {
        if (e.response.status === 401) {
          userStore.setUser({ id: 0, name: '' })
          userStore.setIsAuth(false)
        }
      } else {
        if (e.code) {
          setConnected(false)
        }
      }

    }).finally(() => setLoading(false))
  })
  if (loading) {
    return <Spinner animation={"grow"} style={{ position: 'absolute', top: '50%', left: '50%' }} />
  }
  return (
    <BrowserRouter>
      {connected && <NavBar />}
      {connected && <AppRouter />}
      {!connected && <div>Соединение с сервером потеряно... попробуйте обновить страницу через несколько минут</div>}
    </BrowserRouter>
  );
}
)

export default App;
