import React, { useContext } from 'react'
import { Context } from '../../index'
import { observer } from "mobx-react-lite"
import { Form, InputGroup } from "react-bootstrap"
import { SkipForward, SkipBackward } from 'react-bootstrap-icons';

export const MonthSelector = observer(() => {

    const { configStore } = useContext(Context)

    const stepMonth = (step) => {
        if ((configStore.month + step) === 13) {
            configStore.setMonth(1)
            configStore.setYear(+configStore.year + 1)
        } else {
            if ((configStore.month + step) === 0) {
                configStore.setMonth(12)
                configStore.setYear(+configStore.year - 1)
            } else {
                configStore.setMonth(configStore.month + step)
            }
        }
    }

    return (
        <InputGroup style={{width:'auto'}}>
            <InputGroup.Text role='button' onClick={() => stepMonth(-1)}> <SkipBackward /> </InputGroup.Text>
            <Form.Control size="sm" style={{ maxWidth: "128px" }} value={configStore.monthName} type="text" readOnly={true} />
            <InputGroup.Text role='button'> - </InputGroup.Text>
            <Form.Control size="sm" style={{ maxWidth: "96px" }} value={configStore.year} onChange={e => configStore.setYear(e.target.value)} type="number" min={1970} max={3000} />
            <InputGroup.Text role='button' onClick={() => stepMonth(1)}> <SkipForward /> </InputGroup.Text>
        </InputGroup>
    )
})


