import React, { useContext, useEffect, useState } from "react"
import { Container, Form, Button } from "react-bootstrap"
import { Context } from "../index"
import { observer } from "mobx-react-lite"
import ClientSelector from "./ClientSelector"
import RegionSelector from "./RegionSelector"
import ManagerSelector from "./ManagerSelector"
import Modal from "react-bootstrap/Modal"
import { fetchRegions } from "./http/regionAPI"
import { createClient, updateClient, fetchClients } from "./http/clientAPI"
import { fetchCourses } from "./http/courseAPI"
import { fetchManagers } from "./http/userAPI"
import { fetchBindedManagersByClient, createBinding, updateBinding, removeBinding } from "./http/bindingAPI"
import { MessageAlert } from "./widjets/MessageAlert"

const ClientConfig = observer(() => {
  const { bindingStore, configStore, clientStore, courseStore, managerStore, regionStore } = useContext(Context)

  let [name, setName] = useState("")
  let [inn, setInn] = useState("")
  let [show, setShow] = useState("")

  const regionSelectHandler = () => {
    fetchClients(regionStore.selectedRegion.id).then((data) => {
      clientStore.setClients([clientStore.newRecord, ...data])
      clientStore.setSelectedClient(null)
    })
  }

  useEffect(() => {
    clientStore.setDefaults()
    courseStore.setDefaults()
    bindingStore.setDefaults()
    managerStore.setDefaults()
    regionStore.setDefaults()
    fetchRegions().then((data) => {
      regionStore.setRegions(data)
      regionStore.setSelectedRegion(null)
    })
    fetchCourses().then((data) => {
      courseStore.setCourses(data)
    })
    fetchManagers().then((data) => {
      managerStore.setManagers(data)
    })
  },
    // eslint-disable-next-line
    []
  )

  const clientSelectHandler = () => {
    if (clientStore.selectedClient) {
      setInn(clientStore.selectedClient.inn)
      setName(clientStore.selectedClient.name)
      if (clientStore.selectedClient.id) {
        fetchBindedManagersByClient(clientStore.selectedClient.id).then(data => {
            bindingStore.setBindings(data)
          }
        )
      } else {
        setInn("")
        setName("")
      }
    }
  }

  const CreateOrUpdateClient = async () => {
    let legalINN = /^36\d{8}$/
    if (name.length < 4) {
      configStore.showMessage(
        "В названии должно быть минимум 4 символа",
        "warning"
      )
      return
    }
    if (inn.length < 10 || !legalINN.test(inn)) {
      configStore.showMessage("В ИНН должно быть 10 цифр", "danger")
      return
    }
    if (clientStore.selectedClient.id === null) {
      await createClient(inn, name, regionStore.selectedRegion.id).then(
        (data) => {
          if (data.message) {
            configStore.showMessage(data.message, "warning")
          } else {
            configStore.showMessage(
              `Создано новое учреждение: ${name} (${inn})`,
              "success"
            )
            fetchClients(regionStore.selectedRegion.id).then((data) => {
              clientStore.setClients([clientStore.newRecord, ...data])
            })
            clientStore.setSelectedClient(data)
          }
        }
      )
    } else {
      await updateClient(clientStore.selectedClient.id, inn, name).then(
        (data) => {
          configStore.showMessage(
            `Обновлено учреждение: ${name} ( ${inn} )`,
            "success"
          )
        }
      )
    }
    let storedClientId = clientStore.selectedClient.id
    fetchClients(regionStore.selectedRegion.id).then((data) => {
      clientStore.setClients([clientStore.newRecord, ...data])
      if (clientStore.clients.length > 1) {
        clientStore.setSelectedClient(
          clientStore.clients.find((item) => item.id === storedClientId)
        )
      } else {
        clientStore.setSelectedClient(clientStore.clients[0])
      }
    })
  }

  const CreateOrUpdateBinding = () => {
    if (!managerStore.selectedManager) {
      configStore.showMessage(`Необходимо выбрать менеджера!`, "danger")
      return
    }
    if (bindingStore.selectedBinding.id === 0) {
      createBinding(clientStore.selectedClient.id, bindingStore.selectedBinding.course.id, null, managerStore.selectedManager.id).then((data) => {
        let bindings = [...bindingStore.bindings]
        let binding = bindings.find(binding => binding.course.id === bindingStore.selectedBinding.course.id)
        binding.id = data.id
        binding.user.id = managerStore.selectedManager.id
        binding.user.name = managerStore.selectedManager.name
        bindingStore.setBindings(bindings)
        configStore.showMessage(data.message, "success")
      })
    } else {
      updateBinding(
        bindingStore.selectedBinding.id,
        managerStore.selectedManager.id
      ).then((data) => {
        let bindings = [...bindingStore.bindings]
        let binding = bindings.find(binding => binding.course.id === bindingStore.selectedBinding.course.id)
        binding.user.id = managerStore.selectedManager.id
        binding.user.name = managerStore.selectedManager.name
        bindingStore.setBindings(bindings)
        configStore.showMessage(data.message, "success")
      })
    }
    setShow(false)
  }

  const RemoveBinding = () => {
    if (bindingStore.selectedBinding.id !== 0) {
      removeBinding(bindingStore.selectedBinding.id).then((data) => {
        let bindings = [...bindingStore.bindings]
        let binding = bindings.find(binding => binding.course.id === bindingStore.selectedBinding.course.id)
        binding.id = 0
        binding.user.id = 0
        binding.user.name = 'не выбран'
        bindingStore.setBindings(bindings)
        configStore.showMessage("Удалена привязка", "success")
      })
    } else {
      configStore.showMessage(
        "Привязка не существует в базе данных!",
        "warning"
      )
    }
  }

  return (
    <Container>
      <MessageAlert />

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {clientStore.selectedClient ? clientStore.selectedClient.name : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Курс:{" "}
            {bindingStore.selectedBinding
              ? bindingStore.selectedBinding.course.name
              : ""}
          </div>
          <ManagerSelector />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Отмена
          </Button>
          <Button variant="primary" onClick={() => CreateOrUpdateBinding()}>
            Привязать
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="d-flex justify-content-start flex-wrap mt-2">
        <div className="m-1">
          <RegionSelector selectHandler={regionSelectHandler} />
        </div>
        <div className="m-1">
          <ClientSelector selectHandler={clientSelectHandler} />
        </div>
      </div>

      {clientStore.selectedClient && (
        <div>
          {clientStore.selectedClient.id === null && (
            <Form.Group>
              <Form.Label style={{ color: "#22222250" }}> ИНН: </Form.Label>
              <Form.Control style={{ maxWidth: "196px" }} value={inn} onChange={e => { setInn(e.target.value) }} type="text" placeholder="инн 10 цифр" minLength={10} />
            </Form.Group>
          )}
          {clientStore.selectedClient.id !== null && <div>ИНН: {inn}</div>}

          <Form.Group>
            <Form.Label style={{ width: "auto", color: "#22222250", padding: "3px 2px", margin: "0" }}> Название: </Form.Label>
            <Form.Control style={{ width: "320px", maxWidth: "100%" }} value={name} onChange={e => setName(e.target.value)} type="text" placeholder="название ОУ" minLength={4} />
          </Form.Group>

          <Button className="mt-2" style={{ width: "128px" }} variant="outline-success" onClick={CreateOrUpdateClient} >
            {clientStore.selectedClient.id === null ? "Создать" : "Сохранить"}
          </Button>

          {clientStore.selectedClient.id !== null && (
            <div className="mt-4">
              {bindingStore.bindings.map((binding, bindingIndex) => {
                return (
                  <div key={"r-" + binding.course.id} className="d-flex flex-wrap justify-content-start">
                    <div className="align-self-center" style={{ color: "#22222250", alignSelf: "center", minWidth: "96px" }}>
                      {binding.course.name}
                    </div>
                    <div className="align-self-center">
                      {binding.user.name}
                    </div>
                    {binding.id > 0 && (
                      <Button style={{ minWidth: "96px" }} className="m-1" variant="outline-info" onClick={() => {
                        bindingStore.setSelectedBinding(bindingIndex)
                        RemoveBinding()
                      }}> Отвязать</Button>
                    )}
                    <Button style={{ minWidth: "96px" }} className="m-1" variant="outline-warning" onClick={() => {
                      bindingStore.setSelectedBinding(bindingIndex)
                      setShow(true)
                    }} > Привязать </Button>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      )}
    </Container>
  )
})

export default ClientConfig
