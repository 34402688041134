import {makeAutoObservable} from "mobx"

export default class ClientStore {

    clients = []
    selectedClient = null
    newRecord = {id: null, name: '+', inn: '36', region_id: 0}

    constructor() {
        makeAutoObservable(this)
    }

    setDefaults() {
        this.clients = []
        this.selectedClient = null
    }

    setClients(clients) {
        this.clients = clients
    }

    setSelectedClient(client) {
        this.selectedClient = client
    }

}