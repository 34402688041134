import {$authHost} from "./index"

export const fetchCourses = async () => {
  const {data} = await $authHost.get('course/all')
  return data
}

export const fetchCoursesWithStreams = async () => {
  const {data} = await $authHost.get('course/streams')
  return data
}
