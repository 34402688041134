import React, { useContext } from 'react'
import { Context } from '../index'
import { observer } from "mobx-react-lite"
import Nav from 'react-bootstrap/Nav';


const GroupTabs = observer(({selectHandler}) => {
    const { groupStore } = useContext(Context)
    
    return (
        <Nav variant="tabs"
            id="group-tabs"
            
            activeKey={groupStore.selectedGroup ? groupStore.selectedGroup.id : '0'}
            onSelect={(groupId) => {
                groupStore.setSelectedGroup(groupId)
                selectHandler(groupId)
            }}
            className="mt-4"
        >
            {groupStore.groups.map(group =>
                <Nav.Item key = {group.id}>
                   <Nav.Link eventKey = {group.id}>{group.name+' ('+group.course.name+')'} </Nav.Link>
                </Nav.Item>
            )}

        </Nav>
    );
}
)

export default GroupTabs;