import React, { useContext } from 'react'
import {observer} from 'mobx-react-lite'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import {Context} from '../index'


const TeacherSelector = observer(({selectHandler}) => {
    const {teacherStore} = useContext(Context)
    return (
        <DropdownButton id="dropdown-teachers" variant='outline-secondary' title={teacherStore.selectedTeacher ? teacherStore.teachers.find(teacher => teacher.id === teacherStore.selectedTeacher).name : 'не выбран'}>
            {teacherStore.teachers.map(teacher =>
                <Dropdown.Item size='sm'
                    style={{cursor:"pointer"}}
                    onClick={() => {
                        teacherStore.setSelectedTeacher(teacher.id)
                        selectHandler()
                    }}
                    key={teacher.id}>
                        {teacher.name}
                </Dropdown.Item>
            )}
        </DropdownButton>       
    )
})

export default TeacherSelector