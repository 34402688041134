import {makeAutoObservable} from "mobx"

export default class ConfigStore {

    month = (new Date().getMonth())+1
    year = new Date().getFullYear()
    message = { text: '', status: '', visible: false }
    monthNames = ['', 'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
    monthNamesRP = ['', 'января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря']
    monthName = this.monthNames[this.month]
    monthNameRP = this.monthNamesRP[this.month]
    
    constructor() {
        makeAutoObservable(this)
    }

    setMonth(value) {
        this.month = +value
        this.monthName = this.monthNames[+value]
        this.monthNameRP = this.monthNamesRP[+value]
    }

    setYear(value) {
        this.year = +value
    }

    setMessage(message) {
        this.message = message
    }

    showMessage = (text, status) => {
        this.setMessage({ text, status, visible: true })
        setTimeout(() => {
            this.setMessage({ text: '', status: '', visible: false })
        }, 2000);
      }
    
}