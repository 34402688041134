import {$authHost} from "./index"

export const fetchBindedManagersByClient = async (client_id) => {
  const {data} = await $authHost.get('binding/managers_of_client', {params: {client_id}})
  return data
}

export const fetchBindedManagerByCourse = async (client_id, course_id) => {
  const {data} = await $authHost.get('binding/manager_of_course', {params: {client_id, course_id}})
  return data
}

export const fetchClientsBindedToManager = async () => {
  const {data} = await $authHost.get('binding/clients_of_manager')
  return data
}

export const fetchBindedTeachers = async (client_id, course_id, stream_id) => {
  const {data} = await $authHost.get('binding/teachers_of_stream_by_client', {params: {client_id, course_id, stream_id}})
  return data
}

export const fetchBindedTeachersByClientForManager = async (client_id) => {
  const {data} = await $authHost.get('binding/teachers_by_client_for_manager', {params: {client_id}})
  return data
}


export const fetchBindedClientsByTeacher = async (user_id) => {
  const {data} = await $authHost.get('binding/clients_teacher_working_in', {params: {user_id}})
  return data
}

export const fetchBindedStreamsByTeacher = async (user_id, client_id) => {
  const {data} = await $authHost.get('binding/teachers_of_stream_by_client', {params: {user_id, client_id}})
  return data
}


export const createBinding = async (client_id, course_id, stream_id, user_id) => {
  const {data} = await $authHost.post('binding', {client_id, course_id, stream_id, user_id})
  return data
}

export const updateBinding = async (id, user_id) => {
  const {data} = await $authHost.put('binding', {id, user_id})
  return data
}

export const removeBinding = async (id) => {
  const {data} = await $authHost.delete('binding', {data: {id}})
  return data
}




