import React, {useState, useContext} from 'react'
import {Container, Card, Form, Row, Button} from "react-bootstrap"
import {useNavigate} from "react-router-dom" //NavLink
import {MANAGER_DESKTOP_ROUTE, TEACHER_DESKTOP_ROUTE} from '../consts'
import {login} from './http/userAPI' //register
import {observer} from 'mobx-react-lite'
import {Context} from '../index'

const Auth = observer (() => {
    const {userStore} = useContext(Context)
    // const location = useLocation() // from "react-router-dom"
    // const isLogin = location.pathname === LOGIN_ROUTE
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const navigate =  useNavigate()
  
    const click = async () => {
      try {
        const data = await login(phone, password)
        userStore.setUser(data)
        userStore.setIsAuth(true)
        if (userStore.user.level === 0) {
          navigate(TEACHER_DESKTOP_ROUTE)
        } else {
          navigate(MANAGER_DESKTOP_ROUTE)
        }
      } catch (e) {
        alert(e.response.data.message)
      }
    }
    
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{height: window.innerHeight - 54}}>
        <Card style={{width: "480px", maxWidth: "100%"}} className="p-5">
          <h2 className='m-auto'>Вход в систему</h2>
          <Form className='d-flex flex-column'>
            <Form.Control value = {phone} onChange={e => setPhone(e.target.value)} className="mt-2" placeholder="Телефон в формате 89991234567" type="tel" maxLength="11"/>
            <Form.Control value = {password} onChange={e => setPassword(e.target.value)} className="mt-2" type = "password" placeholder="Пароль.." />
            <Row className='d-flex justify-content-center'>
              <Button variant={"outline-success"} style={{width:'auto'}} onClick = {click} className='mt-2'>Войти</Button>
            </Row>
          </Form>
        </Card>
      </Container>
    )
  }
)
export default Auth