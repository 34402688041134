import {makeAutoObservable} from "mobx"

export default class CourseStore {

    courses = []
    selectedCourse = null

    constructor() {
        makeAutoObservable(this)
    }

    setDefaults() {
        this.courses = []
        this.selectedCourse = null
    }

    setCourses(loadedCourses) {
        this.courses = loadedCourses
    }

    setSelectedCourse(courseId) {
        this.selectedCourse = this.courses.find(course => course.id === courseId)
    }

}