import {$authHost} from "./index"


export const fetchStreams = async () => {
  const {data} = await $authHost.get('stream/all')
  return data
}

export const fetchLocalPrices = async (client_id) => {
  const {data} = await $authHost.get('stream/local', {params: {client_id}})
  return data
}

export const createStream = async (name, price, course_id) => {
  const {data} = await $authHost.post('stream', {name, price, course_id})
  return data
}

export const updateStreamPrice = async (stream_id, price, tax, client_id) => {
  const {data} = await $authHost.post('stream/local', {stream_id, price, tax, client_id})
  return data
}


