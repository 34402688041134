import React, { useContext, useEffect } from 'react'
import { Container, Form, Toast, InputGroup, Button } from "react-bootstrap"
import { Context } from '../index'
import { observer } from "mobx-react-lite"
import { fetchCourses } from './http/courseAPI'
import { fetchStreams, fetchLocalPrices, updateStreamPrice } from './http/streamAPI'
import { fetchRegions } from './http/regionAPI'
import RegionSelector from './RegionSelector'
import { fetchClients } from './http/clientAPI'
import ClientSelector from './ClientSelector'
import { MessageAlert } from './widjets/MessageAlert'

const CourseConfig = observer(() => {
  const { regionStore, configStore, clientStore, courseStore, streamStore } = useContext(Context)

  useEffect(() => {
    clientStore.setDefaults()
    courseStore.setDefaults()
    streamStore.setDefaults()
    regionStore.setDefaults()
    fetchRegions(true).then(data => {
      regionStore.setRegions(data)
    })
  },
  // eslint-disable-next-line
  [])

  const regionSelectHandler = () => {
    fetchClients(regionStore.selectedRegion.id).then(data => {
      clientStore.setClients(data)
      clientStore.setSelectedClient(null)
    })
  }

  const clientSelectHandler = () => {
    fetchCourses().then(data => {
      courseStore.setCourses(data)
    })
    fetchStreams().then(data => {
      let loadedStreams = data
      fetchLocalPrices(clientStore.selectedClient.id).then(data => {
        streamStore.setStreams(loadedStreams, data)
      })
    })
   
  }

  const updateStreamPriceHandler = (streamId) => {
    const streamObj = streamStore.streams.find(stream => stream.id === +streamId)
    updateStreamPrice(streamId, streamObj.price, streamObj.tax, clientStore.selectedClient.id).then(data => {
      configStore.showMessage(data.message, 'success')
    })
  }

  return (
    <Container>

      <MessageAlert />

      <div className='d-flex flex-wrap mt-2'>
        <div className='m-1'>
          <RegionSelector selectHandler = {regionSelectHandler}/>
        </div>
        {regionStore.selectedRegion && 
          <div className='m-1'>
            <ClientSelector selectHandler = {clientSelectHandler}/>
          </div>
        }
      </div>

      {clientStore.selectedClient &&
        <div className='d-flex flex-wrap justify-content-around'>
          {courseStore.courses.map(course => (
            <Toast className='m-2' key={course.id} >
              <Toast.Header closeButton={false} style={{ cursor: 'pointer' }}>
                <strong className="me-auto">{course.name}</strong>
                <small>-</small>
              </Toast.Header>
              <Toast.Body>

                {streamStore.streams.filter(stream => stream.course_id === course.id).map(stream => (
                  <div key={stream.id}> 
                    <div>{stream.name}</div>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>В месяц:</InputGroup.Text>
                      <Form.Control type='number' value={stream.price} onChange={e => {streamStore.setStreamPrice(stream.id, parseInt(e.target.value))}} />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>За ученика:</InputGroup.Text>
                      <Form.Control type='number' value={stream.tax} onChange={e => {streamStore.setStreamTax(stream.id, parseInt(e.target.value))}} />
                    </InputGroup>
                    <Button className='mt-2' style={{ width: "128px" }} variant="outline-success" onClick={() => updateStreamPriceHandler(stream.id)}>Обновить</Button>
                  </div>
                )
                )}

              </Toast.Body>
            </Toast>
          )
          )}
        </div>
      }

    </Container>
  )
})

export default CourseConfig
