import { $authHost } from "./index"

export const fetchClients = async (region_id) => {
  const { data } = await $authHost.get('client/region', { params: { region_id } })
  return data
}

export const fetchClient = async (id) => {
  const { data } = await $authHost.get('client/' + id)
  return data
}

export const createClient = async (inn, name, region_id) => {
  const { data } = await $authHost.post('client', { inn, name, region_id })
  return data
}

export const updateClient = async (id, inn, name, region_id) => {
  const { data } = await $authHost.put('client', { id, inn, name, region_id })
  return data
}

