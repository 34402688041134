import {makeAutoObservable} from "mobx"

export default class TeacherStore {

    teachers = []
    selectedTeacher = null
    defaultValue = {id: null, name: 'ФИО полностью', phone: '89', snils: '000-000-000 00', active: false, courses: []}
    
    constructor() {
        makeAutoObservable(this)
    }

    setDefaults() {
        this.teachers = []
        this.selectedTeacher = null
    }

    setTeachers(teachers) {
        this.teachers = teachers
    }

    setSelectedTeacher(teacher) {
        this.selectedTeacher = teacher
    }

}