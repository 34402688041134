import React, { useContext } from 'react'
import { Context } from '../index'
import { Button, Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { TEACHER_DESKTOP_ROUTE, MANAGER_DESKTOP_ROUTE, LOGIN_ROUTE, CLIENT_CONFIG_ROUTE, TEACHER_CONFIG_ROUTE, COURSE_CONFIG_ROUTE, PAYROLL_ROUTE, REPORT_ROUTE, RESULTS_ROUTE } from "../consts"
import ManualPdf from '../assets/manual_v1.1.pdf'

const NavBar = observer(() => {
  const { userStore } = useContext(Context)
  const navigate = useNavigate()
  const location = useLocation() // from "react-router-dom"
  const isClientConfig = location.pathname === CLIENT_CONFIG_ROUTE
  const isTeacherConfig = location.pathname === TEACHER_CONFIG_ROUTE
  const isCourseConfig = location.pathname === COURSE_CONFIG_ROUTE
  const isPayRollPage = location.pathname === PAYROLL_ROUTE
  const isReportPage = location.pathname === REPORT_ROUTE
  const isResultsPage = location.pathname === RESULTS_ROUTE

  const logOut = () => {
    userStore.setUser({ id: 0, name: ' ' })
    userStore.setIsAuth(false)
    localStorage.removeItem('token')
    navigate(LOGIN_ROUTE)
  }

  return (

    <Navbar expand='sm' className="bg-body-tertiary print-hidden">
      <Container>
        <Navbar.Brand>
          {userStore.user.level === 0 &&
            <NavLink to={TEACHER_DESKTOP_ROUTE} style={{ textDecoration: 'none', color: '#222'}}>Журнал <span style={{fontSize: '12px', color: '#ccc'}}>1.1</span></NavLink>
          }
          {userStore.user.level >= 2 &&
            <NavLink to={MANAGER_DESKTOP_ROUTE} style={{ textDecoration: 'none', color: '#222'}}>Контроль</NavLink>
          }
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="justify-content-end">
          <Nav className='me-auto'>
            {userStore.user.level >= 2 &&
              <NavLink to={PAYROLL_ROUTE} style={{ textDecoration: isPayRollPage ? 'underline' : 'none', padding: '0.5rem', color: '#222', cursor: 'pointer', fontWeight: isPayRollPage ? '500' : '300' }}>Ведомость</NavLink>
            }
            {userStore.user.level === 2 &&
              <NavLink to={REPORT_ROUTE} style={{ textDecoration: isReportPage ? 'underline' : 'none', padding: '0.5rem', color: '#222', cursor: 'pointer', fontWeight: isReportPage ? '500' : '300' }}>Отчет</NavLink>
            }
            {userStore.user.level >= 3 &&
              <NavLink to={RESULTS_ROUTE} style={{ textDecoration: isResultsPage ? 'underline' : 'none', padding: '0.5rem', color: '#222', cursor: 'pointer', fontWeight: isResultsPage ? '500' : '300' }}>Результаты</NavLink>
            }
            {userStore.user.level === 4 &&
              <NavLink to={CLIENT_CONFIG_ROUTE} style={{ textDecoration: isClientConfig ? 'underline' : 'none', padding: '0.5rem', color: '#222', cursor: 'pointer', fontWeight: isClientConfig ? '500' : '300' }}>Учреждения</NavLink>
            }
            {userStore.user.level === 4 &&
              <NavLink to={TEACHER_CONFIG_ROUTE} className={isTeacherConfig ? 'nav-link active' : ''} style={{ textDecoration: isTeacherConfig ? 'underline' : 'none', padding: '0.5rem', color: '#222', fontWeight: isTeacherConfig ? '500' : '300' }}>Сотрудники</NavLink>
            }
            {userStore.user.level === 4 &&
              <NavLink to={COURSE_CONFIG_ROUTE} className={isCourseConfig ? 'nav-link active' : ''} style={{ textDecoration: isCourseConfig ? 'underline' : 'none', padding: '0.5rem', color: '#222', fontWeight: isCourseConfig ? '500' : '300' }}>Курсы</NavLink>
            }
          </Nav>
          <Nav>
            <Navbar.Text className='px-2'>
              {userStore.name}
            </Navbar.Text>
            <Navbar.Text className='px-2'>
              <a href={ManualPdf} target="_blank" rel="noopener noreferrer">Инструкция</a>
            </Navbar.Text>
            {userStore.isAuth && <Button variant={"outline-secondary"} onClick={() => logOut()}>Выйти</Button>}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
})

export default NavBar