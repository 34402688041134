import React, { useContext } from 'react'
import { Context } from '../../index'
import { observer } from "mobx-react-lite"
import { Alert } from "react-bootstrap"

export const MessageAlert = observer(() => {
    const { configStore } = useContext(Context)
    return (
    <div>
        {configStore.message.visible 
            &&
            <Alert 
                className='mt-3 position-fixed w-50 top-10 start-50 translate-middle' 
                style={{zIndex: '9999'}}
                variant={configStore.message.status}
            >
                {configStore.message.text}
            </Alert>}
    </div>
    )
})


