import {makeAutoObservable} from "mobx"

export default class RegionStore {

    regions = []
    selectedRegion = null

    constructor() {
        makeAutoObservable(this)
    }

    setDefaults() {
        this.regions = []
        this.selectedRegion = null
    }

    setRegions(loadedRegions) {
        this.regions = loadedRegions
    }

    setSelectedRegion(regionId) {
        this.selectedRegion =  this.regions.find(region => region.id === regionId)
    }
    
}