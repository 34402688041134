import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Context } from '../index'
import { observer } from "mobx-react-lite"
import { Button, Container, InputGroup, Spinner } from "react-bootstrap"
import { fetchResults } from './http/scopeAPI'
import { MessageAlert } from './widjets/MessageAlert'
import { SkipForward, SkipBackward, ArrowReturnRight } from 'react-bootstrap-icons';
import ManagerSelector from './ManagerSelector'
import { fetchManagers } from './http/userAPI'

const ResultsPage = observer(() => {
  const { configStore, managerStore } = useContext(Context)
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])


  useEffect(() => {
    setResults([])
    configStore.setYear(new Date().getFullYear())
    fetchManagers().then(data =>
      managerStore.setManagers([{ id: 0, name: 'Все' }, ...data])
    )
  },
    // eslint-disable-next-line
    []
  )

  const getResultsData = () => {
    if (!managerStore.selectedManager) {
      configStore.showMessage('Выберите менеджера', 'warning')
      return
    }
    setLoading(true)
    setResults([])
    fetchResults(configStore.year, managerStore.selectedManager.id).then(data => {
      setResults(data)
      console.log(data);
      setLoading(false)
      configStore.showMessage('Отчет сформирован', 'success')
    })
  }

  const stepYear = (step) => {
    configStore.setYear(configStore.year + step)
    setResults([])
  }

  if (loading) {
    return <Spinner animation={"grow"} style={{ position: 'absolute', top: '50%', left: '50%' }} />
  } else {
    return (
      <Container fluid>

        <MessageAlert />

        <div className='mt-2'>
          <InputGroup style={{ width: 'auto' }}>
            <InputGroup.Text role='button' onClick={() => stepYear(-1)}> <SkipBackward /> </InputGroup.Text>
            <InputGroup.Text> {configStore.year + ' - ' + (configStore.year + 1)} </InputGroup.Text>
            <InputGroup.Text role='button' onClick={() => stepYear(1)}> <SkipForward /> </InputGroup.Text>
          </InputGroup>
        </div>

        <div className='mt-2'>
          <ManagerSelector />
        </div>

        <div className='print-hidden mt-3' style={{ display: 'flex' }}>
          <Button style={{ width: "auto" }} variant="outline-success" onClick={() => getResultsData()}>Сформировать</Button>
        </div>

        {results.length === 1 &&
          <div className='d-flex flex-column mt-2'>

            {results.map(manager => (

              <div className='mt-2 w-100 overflow-x-auto'>
                <h4 className='mt-4'>{manager.name}. Отчет за {configStore.year + '-' + (configStore.year + 1)} </h4>
                <hr></hr>
                <table className="table table-bordered" style={{ width: 'auto' }}>
                  <thead>
                    <tr>
                      <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '128px' }}>Курс</th>
                      <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Сентябрь</th>
                      <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Ноябрь</th>
                      <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Декабрь</th>
                      <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Октябрь</th>
                      <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Январь</th>
                      <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Февраль</th>
                      <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Март</th>
                      <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Апрель</th>
                      <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Май</th>
                    </tr>
                  </thead>
                  <tbody>
                    {manager.courses.map((course) => (
                      <Fragment>
                        <tr key={'course-sum-' + course.id}>
                          <td rowSpan={2} style={{ alignContent: 'center', textAlign: 'center' }}>
                            {course.name}
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[9].sum)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[10].sum)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[11].sum)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[12].sum)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[1].sum)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[2].sum)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[3].sum)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[4].sum)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[5].sum)}</div>
                          </td>
                        </tr>
                        <tr key={'course-count-' + course.id}>
                          <td style={{ alignContent: 'center', textAlign: 'center' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[9].count)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[10].count)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[11].count)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[12].count)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[1].count)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[2].count)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[3].count)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[4].count)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[5].count)}</div>
                          </td>
                        </tr>
                      </Fragment>

                    ))}
                  </tbody>
                </table>
              </div>
            ))}






            {results.map(manager => (

              <div className='mt-2 w-100 overflow-x-auto'>
                <h4 className='mt-4'> Детализация по контрагентам:  </h4>
                <hr></hr>
                <table className="table table-bordered" style={{ width: 'auto' }}>
                  <thead>
                    <tr>
                      <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '128px' }}>Контрагент</th>
                      <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '128px' }}>Курс</th>
                      <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Сентябрь</th>
                      <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Ноябрь</th>
                      <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Декабрь</th>
                      <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Октябрь</th>
                      <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Январь</th>
                      <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Февраль</th>
                      <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Март</th>
                      <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Апрель</th>
                      <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Май</th>
                    </tr>
                  </thead>
                  <tbody>
                    {manager.clients.map((client) => (
                      <Fragment>
                        {/* client level */}
                        <tr key={client.id + 'client-sum'}>
                          <td rowSpan={(client.courses.length + 1) * 2} style={{ alignContent: 'center', textAlign: 'center', fontWeight: '500' }}>
                            {client.name}
                          </td>
                          <td rowSpan={2} style={{ alignContent: 'center', textAlign: 'center', fontWeight: '500', backgroundColor: '#cccccc22' }}>
                            всего
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center', fontWeight: '500', backgroundColor: '#cccccc22' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(client.m_values[9].sum)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center', fontWeight: '500', backgroundColor: '#cccccc22' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(client.m_values[10].sum)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center', fontWeight: '500', backgroundColor: '#cccccc22' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(client.m_values[11].sum)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center', fontWeight: '500', backgroundColor: '#cccccc22' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(client.m_values[12].sum)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center', fontWeight: '500', backgroundColor: '#cccccc22' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(client.m_values[1].sum)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center', fontWeight: '500', backgroundColor: '#cccccc22' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(client.m_values[2].sum)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center', fontWeight: '500', backgroundColor: '#cccccc22' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(client.m_values[3].sum)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center', fontWeight: '500', backgroundColor: '#cccccc22' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(client.m_values[4].sum)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center', fontWeight: '500', backgroundColor: '#cccccc22' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(client.m_values[5].sum)}</div>
                          </td>
                        </tr>
                        <tr key={client.id + 'client-count'}>
                          <td style={{ alignContent: 'center', textAlign: 'center', fontWeight: '500', backgroundColor: '#cccccc22' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(client.m_values[9].count)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center', fontWeight: '500', backgroundColor: '#cccccc22' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(client.m_values[10].count)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center', fontWeight: '500', backgroundColor: '#cccccc22' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(client.m_values[11].count)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center', fontWeight: '500', backgroundColor: '#cccccc22' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(client.m_values[12].count)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center', fontWeight: '500', backgroundColor: '#cccccc22' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(client.m_values[1].count)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center', fontWeight: '500', backgroundColor: '#cccccc22' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(client.m_values[2].count)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center', fontWeight: '500', backgroundColor: '#cccccc22' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(client.m_values[3].count)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center', fontWeight: '500', backgroundColor: '#cccccc22' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(client.m_values[4].count)}</div>
                          </td>
                          <td style={{ alignContent: 'center', textAlign: 'center', fontWeight: '500', backgroundColor: '#cccccc22' }}>
                            <div>{new Intl.NumberFormat('ru-RU').format(client.m_values[5].count)}</div>
                          </td>
                        </tr>


                        {/* course level */}
                        {client.courses.map(course => (
                          <Fragment>
                            <tr key={client.id + 'course-sum-' + course.id}>
                              <td rowSpan={2} style={{ alignContent: 'center', textAlign: 'center' }}>
                                {course.name}
                              </td>
                              <td style={{ alignContent: 'center', textAlign: 'center', fontSize: '80%' }}>
                                <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[9].sum)}</div>
                              </td>
                              <td style={{ alignContent: 'center', textAlign: 'center', fontSize: '80%' }}>
                                <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[10].sum)}</div>
                              </td>
                              <td style={{ alignContent: 'center', textAlign: 'center', fontSize: '80%' }}>
                                <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[11].sum)}</div>
                              </td>
                              <td style={{ alignContent: 'center', textAlign: 'center', fontSize: '80%' }}>
                                <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[12].sum)}</div>
                              </td>
                              <td style={{ alignContent: 'center', textAlign: 'center', fontSize: '80%' }}>
                                <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[1].sum)}</div>
                              </td>
                              <td style={{ alignContent: 'center', textAlign: 'center', fontSize: '80%' }}>
                                <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[2].sum)}</div>
                              </td>
                              <td style={{ alignContent: 'center', textAlign: 'center', fontSize: '80%' }}>
                                <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[3].sum)}</div>
                              </td>
                              <td style={{ alignContent: 'center', textAlign: 'center', fontSize: '80%' }}>
                                <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[4].sum)}</div>
                              </td>
                              <td style={{ alignContent: 'center', textAlign: 'center', fontSize: '80%' }}>
                                <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[5].sum)}</div>
                              </td>
                            </tr>
                            <tr key={client.id + 'course-count-' + course.id}>
                              <td style={{ alignContent: 'center', textAlign: 'center', fontSize: '80%' }}>
                                <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[9].count)}</div>
                              </td>
                              <td style={{ alignContent: 'center', textAlign: 'center', fontSize: '80%' }}>
                                <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[10].count)}</div>
                              </td>
                              <td style={{ alignContent: 'center', textAlign: 'center', fontSize: '80%' }}>
                                <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[11].count)}</div>
                              </td>
                              <td style={{ alignContent: 'center', textAlign: 'center', fontSize: '80%' }}>
                                <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[12].count)}</div>
                              </td>
                              <td style={{ alignContent: 'center', textAlign: 'center', fontSize: '80%' }}>
                                <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[1].count)}</div>
                              </td>
                              <td style={{ alignContent: 'center', textAlign: 'center', fontSize: '80%' }}>
                                <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[2].count)}</div>
                              </td>
                              <td style={{ alignContent: 'center', textAlign: 'center', fontSize: '80%' }}>
                                <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[3].count)}</div>
                              </td>
                              <td style={{ alignContent: 'center', textAlign: 'center', fontSize: '80%' }}>
                                <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[4].count)}</div>
                              </td>
                              <td style={{ alignContent: 'center', textAlign: 'center', fontSize: '80%' }}>
                                <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[5].count)}</div>
                              </td>
                            </tr>
                          </Fragment>
                        ))}

                      </Fragment>

                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        }



        {results.length > 1 &&
          <div className='mt-2'>

            <h4 className='mt-4'>Отчет за {configStore.year + '-' + (configStore.year + 1)} </h4>
            <hr></hr>
            <table className="table table-bordered" style={{ width: 'auto' }}>
              <thead>
                <tr>
                  <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '128px' }}>Менеджер</th>
                  <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Сентябрь</th>
                  <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Ноябрь</th>
                  <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Декабрь</th>
                  <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Октябрь</th>
                  <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Январь</th>
                  <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Февраль</th>
                  <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Март</th>
                  <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Апрель</th>
                  <th scope="col" style={{ alignContent: 'center', textAlign: 'center', width: '96px' }}>Май</th>
                </tr>
              </thead>
              <tbody>

                {results.map(manager => (
                  <Fragment>
                    <tr key={'manager-sum-' + manager.id}>
                      <td rowSpan={2} style={{ alignContent: 'center', textAlign: 'center' }}>
                        {manager.name}
                      </td>
                      <td style={{ alignContent: 'center', textAlign: 'center' }}>
                        <div>{new Intl.NumberFormat('ru-RU').format(manager.m_values[9].sum)}</div>
                      </td>
                      <td style={{ alignContent: 'center', textAlign: 'center' }}>
                        <div>{new Intl.NumberFormat('ru-RU').format(manager.m_values[10].sum)}</div>
                      </td>
                      <td style={{ alignContent: 'center', textAlign: 'center' }}>
                        <div>{new Intl.NumberFormat('ru-RU').format(manager.m_values[11].sum)}</div>
                      </td>
                      <td style={{ alignContent: 'center', textAlign: 'center' }}>
                        <div>{new Intl.NumberFormat('ru-RU').format(manager.m_values[12].sum)}</div>
                      </td>
                      <td style={{ alignContent: 'center', textAlign: 'center' }}>
                        <div>{new Intl.NumberFormat('ru-RU').format(manager.m_values[1].sum)}</div>
                      </td>
                      <td style={{ alignContent: 'center', textAlign: 'center' }}>
                        <div>{new Intl.NumberFormat('ru-RU').format(manager.m_values[2].sum)}</div>
                      </td>
                      <td style={{ alignContent: 'center', textAlign: 'center' }}>
                        <div>{new Intl.NumberFormat('ru-RU').format(manager.m_values[3].sum)}</div>
                      </td>
                      <td style={{ alignContent: 'center', textAlign: 'center' }}>
                        <div>{new Intl.NumberFormat('ru-RU').format(manager.m_values[4].sum)}</div>
                      </td>
                      <td style={{ alignContent: 'center', textAlign: 'center' }}>
                        <div>{new Intl.NumberFormat('ru-RU').format(manager.m_values[5].sum)}</div>
                      </td>
                    </tr>
                    <tr key={'manager-count-' + manager.id}>
                      <td style={{ alignContent: 'center', textAlign: 'center' }}>
                        <div>{new Intl.NumberFormat('ru-RU').format(manager.m_values[9].count)}</div>
                      </td>
                      <td style={{ alignContent: 'center', textAlign: 'center' }}>
                        <div>{new Intl.NumberFormat('ru-RU').format(manager.m_values[10].count)}</div>
                      </td>
                      <td style={{ alignContent: 'center', textAlign: 'center' }}>
                        <div>{new Intl.NumberFormat('ru-RU').format(manager.m_values[11].count)}</div>
                      </td>
                      <td style={{ alignContent: 'center', textAlign: 'center' }}>
                        <div>{new Intl.NumberFormat('ru-RU').format(manager.m_values[12].count)}</div>
                      </td>
                      <td style={{ alignContent: 'center', textAlign: 'center' }}>
                        <div>{new Intl.NumberFormat('ru-RU').format(manager.m_values[1].count)}</div>
                      </td>
                      <td style={{ alignContent: 'center', textAlign: 'center' }}>
                        <div>{new Intl.NumberFormat('ru-RU').format(manager.m_values[2].count)}</div>
                      </td>
                      <td style={{ alignContent: 'center', textAlign: 'center' }}>
                        <div>{new Intl.NumberFormat('ru-RU').format(manager.m_values[3].count)}</div>
                      </td>
                      <td style={{ alignContent: 'center', textAlign: 'center' }}>
                        <div>{new Intl.NumberFormat('ru-RU').format(manager.m_values[4].count)}</div>
                      </td>
                      <td style={{ alignContent: 'center', textAlign: 'center' }}>
                        <div>{new Intl.NumberFormat('ru-RU').format(manager.m_values[5].count)}</div>
                      </td>
                    </tr>
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        }

      </Container>
    )
  }
})

export default ResultsPage