import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../index'
import { observer } from "mobx-react-lite"
import { Button, Container, InputGroup, Spinner } from "react-bootstrap"
import { fetchReport } from './http/scopeAPI'
import { MessageAlert } from './widjets/MessageAlert'
import { SkipForward, SkipBackward } from 'react-bootstrap-icons';



const ReportPage = observer(() => {
  const { userStore, configStore } = useContext(Context)
  const [loading, setLoading] = useState(false)
  const [report, setReport] = useState([])


  useEffect(() => {
    setReport([])
    configStore.setYear(new Date().getFullYear()) 
  },
    // eslint-disable-next-line
    []
  )

  useEffect(() => {
    setReport([])
    getReportData()
  },
    // eslint-disable-next-line
    [configStore.year]
  )

  const getReportData = () => {
    setLoading(true)
    fetchReport(configStore.year, userStore.user.id).then(data => {
      setReport(data)
      setLoading(false)
      configStore.showMessage('Отчет сформирован', 'success')
    })
  }

  const stepYear = (step) => {
    configStore.setYear(configStore.year+step)
  }

  if (loading) {
    return <Spinner animation={"grow"} style={{ position: 'absolute', top: '50%', left: '50%' }} />
  } else {
    return (
      <Container fluid>

        <MessageAlert />

        <div className='mt-2'>
        <InputGroup style={{width:'auto'}}>
          <InputGroup.Text role='button' onClick={() => stepYear(-1)}> <SkipBackward /> </InputGroup.Text>
          <InputGroup.Text> {configStore.year + ' - ' + (configStore.year+1)} </InputGroup.Text>
          <InputGroup.Text role='button' onClick={() => stepYear(1)}> <SkipForward /> </InputGroup.Text>
        </InputGroup>
        </div>

        <div className='print-hidden mt-3' style={{display: 'flex'}}>
          <Button style={{ width: "auto" }} variant="outline-success" onClick={() => getReportData()}>Обновить</Button>
        </div>

        {report.length > 0 &&
          <div className='d-flex flex-column mt-2'>
            <h3 className='mt-4'>Отчет за {configStore.year + '-' + (configStore.year+1)} </h3>
            <hr></hr>
            
            <div className='mt-2 w-100 overflow-x-auto'>
              <table className="table table-bordered" style={{width: 'auto'}}>
                <thead>
                  <tr>
                    <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '32px'}}>Курс</th>
                    <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '96px'}}>Сентябрь</th>
                    <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '96px'}}>Октябрь</th>
                    <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '96px'}}>Ноябрь</th>
                    <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '96px'}}>Декабрь</th>
                    <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '96px'}}>Январь</th>
                    <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '96px'}}>Февраль</th>
                    <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '96px'}}>Март</th>
                    <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '96px'}}>Апрель</th>
                    <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '96px'}}>Май</th>
                  </tr>
                </thead>
                <tbody> 
                  {report.map((course) => (
                      <tr key={'course-' + course.id}>
                         <td style={{alignContent: 'center', textAlign: 'center'}}>
                            {course.name}
                          </td>
                          <td style={{alignContent: 'center', textAlign: 'center'}}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[9].sum)}</div>
                          </td>
                          <td style={{alignContent: 'center', textAlign: 'center'}}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[10].sum)}</div>
                          </td>
                          <td style={{alignContent: 'center', textAlign: 'center'}}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[11].sum)}</div>
                          </td>
                          <td style={{alignContent: 'center', textAlign: 'center'}}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[12].sum)}</div>
                          </td>
                          <td style={{alignContent: 'center', textAlign: 'center'}}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[1].sum)}</div>
                          </td>
                          <td style={{alignContent: 'center', textAlign: 'center'}}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[2].sum)}</div>
                          </td>
                          <td style={{alignContent: 'center', textAlign: 'center'}}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[3].sum)}</div>
                          </td>
                          <td style={{alignContent: 'center', textAlign: 'center'}}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[4].sum)}</div>
                          </td>
                          <td style={{alignContent: 'center', textAlign: 'center'}}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[5].sum)}</div>
                          </td>
                      </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className='mt-4 w-100 overflow-x-auto'>
              <table className="table table-bordered" style={{width: 'auto'}}>
                <thead>
                  <tr>
                    <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '32px'}}>Курс</th>
                    <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '96px'}}>Сентябрь</th>
                    <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '96px'}}>Октябрь</th>
                    <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '96px'}}>Ноябрь</th>
                    <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '96px'}}>Декабрь</th>
                    <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '96px'}}>Январь</th>
                    <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '96px'}}>Февраль</th>
                    <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '96px'}}>Март</th>
                    <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '96px'}}>Апрель</th>
                    <th scope="col" style={{alignContent: 'center', textAlign: 'center', width: '96px'}}>Май</th>
                  </tr>
                </thead>
                <tbody> 
                  {report.map((course) => (
                      <tr key={'course-' + course.id}>
                         <td style={{alignContent: 'center', textAlign: 'center'}}>
                            {course.name}
                          </td>
                          <td style={{alignContent: 'center', textAlign: 'center'}}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[9].count)}</div>
                          </td>
                          <td style={{alignContent: 'center', textAlign: 'center'}}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[10].count)}</div>
                          </td>
                          <td style={{alignContent: 'center', textAlign: 'center'}}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[11].count)}</div>
                          </td>
                          <td style={{alignContent: 'center', textAlign: 'center'}}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[12].count)}</div>
                          </td>
                          <td style={{alignContent: 'center', textAlign: 'center'}}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[1].count)}</div>
                          </td>
                          <td style={{alignContent: 'center', textAlign: 'center'}}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[2].count)}</div>
                          </td>
                          <td style={{alignContent: 'center', textAlign: 'center'}}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[3].count)}</div>
                          </td>
                          <td style={{alignContent: 'center', textAlign: 'center'}}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[4].count)}</div>
                          </td>
                          <td style={{alignContent: 'center', textAlign: 'center'}}>
                            <div>{new Intl.NumberFormat('ru-RU').format(course.m_values[5].count)}</div>
                          </td>
                      </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        }
      </Container>
    )
  }
})

export default ReportPage