import React, { useContext } from 'react'
import {observer} from 'mobx-react-lite'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import {Context} from '../index'


const RegionSelector = observer(({selectHandler}) => {
    const {regionStore} = useContext(Context)
    return (
        <DropdownButton id="dropdown-regions" variant='outline-secondary' title={regionStore.selectedRegion ? regionStore.selectedRegion.name : 'Выберите регион'}>
            {regionStore.regions.map(region =>
                <Dropdown.Item size='sm'
                    style={{cursor:"pointer"}}
                    onClick={() => {
                        regionStore.setSelectedRegion(region.id)
                        selectHandler()
                    }}
                    key={region.id}>
                        {region.name}
                </Dropdown.Item>
            )}
        </DropdownButton>       
    )
})

export default RegionSelector