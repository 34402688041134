import {makeAutoObservable} from "mobx"

export default class ManagerStore {
    
    managers = []
    selectedManager = null
        
    constructor() {
        makeAutoObservable(this)
    }

    setDefaults() {
        this.managers = []
        this.selectedManager = null
    }

    setManagers(managers) {
        this.managers = managers
    }

    setSelectedManager(managerId) {
        this.selectedManager = this.managers.find(manager => manager.id === +managerId)
    }

}