import {makeAutoObservable} from "mobx"

export default class StreamStore {

    streams = []
    selectedStream = null

    constructor() {
        makeAutoObservable(this)
    }

    setDefaults() {
        this.streams = []
        this.selectedStream = null
    }

    setStreams(loadedStreams, localPrices) {
        loadedStreams.forEach(stream => {
            let localPrice = localPrices.find(record => record.stream_id === stream.id)
            stream.price = localPrice ? localPrice.price : 0
            stream.tax = localPrice ? localPrice.tax : 0
        })
        this.streams = loadedStreams
    }

    setSelectedStream(streamId) {
        this.selectedStream = this.streams.find(stream => stream.id === +streamId)
    }

    setStreamPrice(streamId, newPrice) {
        this.streams.find(stream => stream.id === +streamId).price = newPrice
    }
    setStreamTax(streamId, newTax) {
        this.streams.find(stream => stream.id === +streamId).tax = newTax
    }

}