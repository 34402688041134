import React, { useContext } from 'react'
import {observer} from 'mobx-react-lite'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import {Context} from '../index'


const CourseStreamSelector = observer(({selectHandler}) => {
    const {courseStore, streamStore} = useContext(Context)
    return (
        <DropdownButton variant = 'outline-secondary' id="dropdown-courses-with-streams" title={streamStore.selectedStream ? courseStore.selectedCourse.name+' - '+streamStore.selectedStream.name : ' - // -'}>
            {courseStore.courses.map(course =>
                (streamStore.streams.filter(stream => stream.course_id === course.id).map(stream =>
                    <Dropdown.Item 
                    style={{cursor:"pointer"}}
                    onClick={() => {
                        courseStore.setSelectedCourse(course.id)
                        streamStore.setSelectedStream(stream.id)
                        selectHandler()
                    }}
                    key={stream.id}>
                        {course.name} - {stream.name}
                    </Dropdown.Item>
                    )
                )
            )}
           
        </DropdownButton>       
    )
})

export default CourseStreamSelector