import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Context } from "../index";

const ManagerSelector = observer(({ selectHandler }) => {
  const { managerStore } = useContext(Context);
  return (
    <DropdownButton title={managerStore.selectedManager ? managerStore.selectedManager.name : "выберите менеджера"} variant="outline-secondary">
      {managerStore.managers.map((manager) => (
        <Dropdown.Item
          key={manager.id}
          style={{ cursor: "pointer" }}
          onClick={() => {
            managerStore.setSelectedManager(manager.id);
          }}
        > {manager.name} </Dropdown.Item>
      ))}
    </DropdownButton>
  );
});

export default ManagerSelector;
