import Auth from './components/Auth'

import ClientConfig from './components/ClientConfig'
import TeacherConfig from './components/TeacherConfig'
import CourseConfig from './components/CourseConfig'
import TeacherDesktop from './components/TeacherDesktop'
import ManagerDesktop from './components/ManagerDesktop'
import PayrollPage from './components/PayrollPage'
import ReportPage from './components/ReportPage'
import ResultsPage from './components/ResultsPage'


import {MANAGER_DESKTOP_ROUTE, TEACHER_DESKTOP_ROUTE, LOGIN_ROUTE, CLIENT_CONFIG_ROUTE, TEACHER_CONFIG_ROUTE, COURSE_CONFIG_ROUTE, PAYROLL_ROUTE, REPORT_ROUTE, RESULTS_ROUTE} from './consts'

export const authRoutes = [
    {
        path: RESULTS_ROUTE,
        Component: ResultsPage
    },
    {
        path: PAYROLL_ROUTE,
        Component: PayrollPage
    },
    {
        path: REPORT_ROUTE,
        Component: ReportPage
    },
    {
        path: MANAGER_DESKTOP_ROUTE,
        Component: ManagerDesktop
    },
    {
        path: TEACHER_DESKTOP_ROUTE,
        Component: TeacherDesktop
    },
    {
        path: CLIENT_CONFIG_ROUTE,
        Component: ClientConfig
    },
    {
        path: TEACHER_CONFIG_ROUTE,
        Component: TeacherConfig
    },
    {
        path: COURSE_CONFIG_ROUTE,
        Component: CourseConfig
    },
]

export const publicRoutes = [
    {
        path: LOGIN_ROUTE,
        Component: Auth
    }
]


