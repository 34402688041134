import {$authHost, $host} from "./index"
import { jwtDecode } from "jwt-decode"

// export const register = async (email, password, name) => {
//   const {data} = await $host.post('user/register', {email, password, name})
//   localStorage.setItem('token', data.token)
//   return jwtDecode(data.token)
// }

export const login = async (phone, password) => {
  const {data} = await $host.post('user/login', {phone, password})
  localStorage.setItem('token', data.token)
  return jwtDecode(data.token)
}

export const check = async () => {
  const {data} = await $authHost.get('user/auth')
  localStorage.setItem('token', data.token)
  return jwtDecode(data.token)
}

export const fetchManagers = async () => {
  const {data} = await $authHost.get('user/managers')
  return data
}

export const fetchTeachersByClient = async (client_id) => {
  const {data} = await $authHost.get('user/teachers/client', {params: {client_id}})
  return data
}

export const fetchTeacherByInfo = async (phone, snils) => {
  const {data} = await $authHost.get('user/teacher', {params: {phone, snils}})
  return data
}

export const createTeacher = async (phone, snils, name) => {
  const {data} = await $authHost.post('user/teacher', { phone, snils, name })
  return data
}

export const updateTeacher = async (id, phone, name) => {
  const {data} = await $authHost.put('user/teacher', { id, phone, name })
  return data
}

export const setPassword = async (id, password) => {
  const {data} = await $authHost.post('user/change/password', { id, password })
  return data
}

export const setStatus = async (id, active) => {
  const {data} = await $authHost.post('user/change/status', { id, active })
  return data
}

export const fetchTeacher = async (phone) => {
  const {data} = await $authHost.get('user/teacher', { params: {phone} })
  return data
}