import React, {createContext} from 'react'
import ReactDOM from 'react-dom/client'

import BindingStore from './store/BindingStore'
import ClientStore from './store/ClientStore'
import ConfigStore from './store/ConfigStore'
import CourseStore from './store/CourseStore'
import GroupStore from './store/GroupStore'
import ManagerStore from './store/ManagerStore'
import RegionStore from './store/RegionStore'
import ScopeStore from './store/ScopeStore'
import StreamStore from './store/StreamStore'
import TeacherStore from './store/TeacherStore'
import UserStore from './store/UserStore'

import './assets/main.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import App from './App'

export const Context = createContext(null)
// version control 04 02 2024
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Context.Provider value={
        {
          bindingStore: new BindingStore(),
          clientStore: new ClientStore(),
          configStore: new ConfigStore(),
          courseStore: new CourseStore(),
          groupStore: new GroupStore(),
          managerStore: new ManagerStore(),
          regionStore: new RegionStore(),
          scopeStore: new ScopeStore(),
          streamStore: new StreamStore(),
          teacherStore: new TeacherStore(),
          userStore: new UserStore()
        }
      }>
      <App />
    </Context.Provider> 
  </React.StrictMode>
);