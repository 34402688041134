import React, { useContext } from 'react'
import {observer} from 'mobx-react-lite'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import {Context} from '../index'


const ClientSelector = observer(({selectHandler}) => {
    const {clientStore} = useContext(Context)
    return (
        <DropdownButton id="dropdown-clients" variant='outline-secondary'  title={clientStore.selectedClient ? clientStore.selectedClient.name : 'Выберите ОУ'}>
            {clientStore.clients.map(currentItem =>
                <Dropdown.Item 
                    style={{cursor:"pointer"}}
                    onClick={() => {
                        clientStore.setSelectedClient(currentItem)
                        selectHandler()
                    }}
                    key={currentItem.id}>
                        {currentItem.name}
                </Dropdown.Item>
            )}
        </DropdownButton>       
    )
})

export default ClientSelector