// export const API_BASE_URL = 'http://localhost:5000/api'
export const API_BASE_URL = 'https://xn--j1ab.xn--e1afhibbfeqsi.xn--p1ai/api'
export const LOGIN_ROUTE = '/login'
export const CLIENT_CONFIG_ROUTE = '/client/config'
export const TEACHER_CONFIG_ROUTE = '/teacher/config'
export const COURSE_CONFIG_ROUTE = '/course/config'
export const MANAGER_DESKTOP_ROUTE = '/manager/desktop'
export const TEACHER_DESKTOP_ROUTE = '/teacher/desktop'
export const PAYROLL_ROUTE = '/manager/payroll'
export const REPORT_ROUTE = '/manager/report'
export const RESULTS_ROUTE = '/chief/results'
// export const REGISTRATION_ROUTE = '/register'

export const nameAppearenсe = (name) => {
    let f, i, o, fio
    fio = name.split(' ')
    if (fio.length === 3) {
        f = fio[0]
        i = fio[1].length > 1 ? fio[1][0] : fio[1]
        o = fio[2].length > 1 ? fio[2][0] : fio[2]
        return f+' '+i+'.'+o+'.'
    } 
    return name
}
