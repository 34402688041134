import {$authHost} from "./index"

export const fetchGroupsByTeacher = async (teacher_id, month, year) => {
  const {data} = await $authHost.get('group/teacher', {params: {teacher_id, month, year}})
  return data
}

export const createGroup = async (name, client_id, course_id, stream_id, teacher_id, month, year) => {
  const {data} = await $authHost.post('group', {name, client_id, course_id, stream_id, teacher_id, month, year}, {
    headers: {
      'month': month,
      'year': year,
    }
  })
  return data
}

export const updateGroup = async (id, name, month, year) => {
  const {data} = await $authHost.put('group', {id, name}, {
    headers: {
      'month': month,
      'year': year,
    }
  })
  return data
}

export const fetchStudents = async (group_id) => {
  const {data} = await $authHost.get('student/group/list', {params: {group_id}})
  return data
}

export const fetchStudentsWithJournals = async (group_id, month, year) => {
  const {data} = await $authHost.get('student/group/list/journal', {params: {group_id, month, year}})
  return data
}

export const fetchStudentsWithHistory = async (group_id, month, year) => {
  const {data} = await $authHost.get('student/group/list/history', {params: {group_id, month, year}})
  return data
}

export const createStudent = async (name, group_id, month, year) => {
  const {data} = await $authHost.post('student', {name, group_id}, {
    headers: {
      'month': month,
      'year': year,
    }
  })
  return data
}

export const updateStudent = async (id, name, group_id, active, month, year) => {
  const {data} = await $authHost.put('student', {id, name, group_id, active}, {
    headers: {
      'month': month,
      'year': year,
    }
  })
  return data
}

export const createJournal = async (student_id, group_id, client_id, month, year) => {
  const {data} = await $authHost.post('student/journal', {student_id, group_id, client_id, month, year}, {
    headers: {
      'month': month,
      'year': year,
    }
  })
  return data
}

export const createJournals = async (students, group_id, client_id, month, year) => {
  const {data} = await $authHost.post('student/journals', {students, group_id, client_id, month, year}, {
    headers: {
      'month': month,
      'year': year,
    }
  })
  return data
}

export const updateJournal = async (id, lessons, credit, debit, info, month, year) => {
    const {data} = await $authHost.put('student/journal', {id, lessons, credit, debit, info}, {
      headers: {
        'month': month,
        'year': year,
      }
    })
    return data
}

export const removeJournal = async (id, month, year) => {
    const {data} = await $authHost.delete('student/journal', {data: {id}}, {
      headers: {
        'month': month,
        'year': year,
      }
    })
    return data
}

export const fetchLessons = async (month, year, group_id) => {
  const {data} = await $authHost.get('group/lessons', {params: {month, year, group_id}})
  return data
}

export const fetchTopics = async (course_id, subject_id) => {
  const {data} = await $authHost.get('group/topics', {params: {course_id, subject_id}})
  return data
}

export const fetchSubjects = async (course_id) => {
  const {data} = await $authHost.get('group/subjects', {params: {course_id}})
  return data
}

export const createLesson = async (day, month, year, theme, group_id) => {
  const {data} = await $authHost.post('group/lesson', {day, month, year, theme, group_id}, {
    headers: {
      'month': month,
      'year': year,
    }
  })
  return data
}

export const updateLesson = async (id, day, theme, month, year) => {
    const {data} = await $authHost.put('group/lesson', {id, day, theme}, {
      headers: {
        'month': month,
        'year': year,
      }
    })
    return data
}

export const deleteLesson = async (id, month, year) => {
    const {data} = await $authHost.delete('group/lesson', {data: {id}}, {
      headers: {
        'month': month,
        'year': year,
      }
    })
    return data
}
