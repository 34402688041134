import {$authHost} from "./index"


export const fetchPayroll = async (year, month, manager_id, client_id) => {
  const {data} = await $authHost.get('scope/payroll', { params: {year, month, manager_id, client_id} })
  return data
}

export const fetchReport = async (year, manager_id) => {
  const {data} = await $authHost.get('scope/report', { params: {year, manager_id} })
  return data
}

export const fetchResults = async (year, manager_id) => {
  const {data} = await $authHost.get('scope/results', { params: {year, manager_id} })
  return data
}

export const fetchWageStatus = async (year, month, group_id) => {
  const {data} = await $authHost.get('scope/wage', { params: {year, month, group_id} })
  return data
}

export const createOrUpdateTeacherWage = async (year, month, teacher_id, course_id, amount) => {
  const {data} = await $authHost.post('scope/wage', {year, month, teacher_id, course_id, amount})
  return data
}

export const createOrUpdateScope = async (year, month, manager_id, client_id, chief, assist, extra, comment) => {
  const {data} = await $authHost.post('scope', {year, month, manager_id, client_id, chief, assist, extra, comment})
  return data
}

