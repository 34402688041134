
import React, { useContext, useEffect, useState } from 'react'
import { Container, Form, Button, Toast, Alert } from "react-bootstrap"
import { Context } from '../index'
import ClientSelector from "./ClientSelector"
import RegionSelector from "./RegionSelector"
import { observer } from "mobx-react-lite"
import { fetchRegions } from './http/regionAPI'
import { fetchClients } from './http/clientAPI'
import { fetchCourses } from './http/courseAPI'
import { fetchTeachersByClient, fetchTeacherByInfo, createTeacher, updateTeacher, setPassword, setStatus } from './http/userAPI'
import { nameAppearenсe } from '../consts'
import CourseStreamSelector from './CourseStreamSelector'
import { createBinding } from './http/bindingAPI'
import { fetchStreams } from './http/streamAPI'
// import StreamSelector from './StreamSelector'


const TeacherConfig = observer(() => {
  const { regionStore, clientStore, courseStore, streamStore, teacherStore } = useContext(Context)


  let [name, setName] = useState('')
  let [phone, setPhone] = useState('')
  let [snils, setSnils] = useState('')
  let [view, setView] = useState({})
  let [message, setMessage] = useState({ text: '', status: '', visible: false })

  useEffect(() => {
    clientStore.setDefaults()
    courseStore.setDefaults()
    streamStore.setDefaults()
    fetchRegions(false).then(data => {
      regionStore.setRegions(data)
      regionStore.setSelectedRegion(null)
    })
    fetchCourses().then(data => {
      courseStore.setCourses(data)
    })
    fetchStreams().then(data => {
      streamStore.setStreams(data, [])
    })
  },// eslint-disable-next-line
    [])

  const showMessage = (text, status) => {
    setMessage({ text, status, visible: true })
    setTimeout(() => {
      setMessage({ ...message, visible: false })
    }, 2000);
  }

  const regionSelectHandler = () => {
    fetchClients(regionStore.selectedRegion.id).then(data => {
      clientStore.setClients(data)
      clientStore.setSelectedClient(null)
    })
  }

  const clientSelectHandler = () => {
    fetchTeachersByClient(clientStore.selectedClient.id).then(data => {
      teacherStore.setTeachers(data)
      teacherStore.setSelectedTeacher(null)
      teacherStore.teachers.forEach(teacher => {
        setView({ ...view, [teacher.id]: false })
      });
    })
    setName('')
    setPhone('')
    setSnils('')
  }

  const findTeacher = () => {
    fetchTeacherByInfo(phone, snils).then(data => {
      if (data.id) {
        setName(data.name)
        setSnils(data.snils)
        setPhone(data.phone)
        showMessage('Найден преподаватель', 'success')
        teacherStore.setSelectedTeacher({ ...data, courses: [] })
        console.log(data);
      } else {
        showMessage('Не найден преподаватель с такими данными', 'info')
        teacherStore.setSelectedTeacher(teacherStore.defaultValue)
      }
    })
  }

  const CreateOrUpdateTeacher = () => {

    if (teacherStore.selectedTeacher.id === null) {
      let legalSnills = /^\d{3}-\d{3}-\d{3}\s\d{2}$/;
      let legalPhone = /^89\d{9}$/;
      let legalName = /^\D+\s\D+\s\D+$/;
      if (!legalSnills.test(snils)) {
        showMessage('Введите СНИЛС в корректном формате', 'danger')
        return
      }
      if (!legalPhone.test(phone)) {
        showMessage('Введите телефон в корректном формате', 'danger')
        return
      }
      if (!legalName.test(name)) {
        showMessage('Введите ФИО полностью', 'danger')
        return
      }
      createTeacher(phone, snils, name).then(data => {
        if (data.id) {
          teacherStore.setSelectedTeacher({ ...data, courses: [] })
        } else {
          teacherStore.setSelectedTeacher(teacherStore.defaultValue)
        }
        showMessage(`Преподаватель ${nameAppearenсe(name)} (${snils}) внесён в базу`, 'success')
      })

    }
    else {
      updateTeacher(teacherStore.selectedTeacher.id, phone, name).then(data => {
        showMessage(`Данные преподавателя ${nameAppearenсe(name)} (${snils}) обновлены`, 'success')
      })
    }

  }

  const сhangeStatus = () => {
    if (teacherStore.selectedTeacher.active) {
      setStatus(teacherStore.selectedTeacher.id, 0)
      teacherStore.setSelectedTeacher({ ...teacherStore.selectedTeacher, active: false })
    } else {
      setPassword(teacherStore.selectedTeacher.id, teacherStore.selectedTeacher.phone)
      setStatus(teacherStore.selectedTeacher.id, 1)
      teacherStore.setSelectedTeacher({ ...teacherStore.selectedTeacher, active: true })
    }
    
  }

  const saveTeacherBinding = (teacherId) => {
    createBinding(clientStore.selectedClient.id, courseStore.selectedCourse.id, streamStore.selectedStream.id, teacherId).then(data => {
      if (data.id) {
        showMessage(`Данные преподавателя ${nameAppearenсe(name)} (${snils}) обновлены`, 'success')
        clientSelectHandler()
      } else {
        showMessage(`${nameAppearenсe(name)} уже ведёт ${courseStore.selectedCourse.name + ' - ' + streamStore.selectedStream.name}`, 'warning')
      }
    })
  }

  const csSelectHandler = () => {
   return true;
  }

  return (
    <Container>

      <h4 className='mt-4' style={{ color: '#3c3c3c' }}> Преподаватели в выбранном ОУ: </h4>
      <hr />

      {message.visible && <Alert className='mt-3 position-fixed z-3 w-50 top-10 start-50 translate-middle' variant={message.status}>{message.text}</Alert>}

      <div className='d-flex justify-content-start flex-wrap mt-2'>
        <div className='m-1'>
          <RegionSelector selectHandler={regionSelectHandler} />
        </div>
        <div className='m-1'>
          <ClientSelector selectHandler={clientSelectHandler} />
        </div>
      </div>

      {clientStore.selectedClient &&
        <div className={teacherStore.teachers.length > 2 ? 'd-flex flex-wrap justify-content-around mt-2' : 'd-flex mt-2'}>
          {
            teacherStore.teachers.map(teacher => {
              return (
                <Toast className='m-2' key={teacher.phone} >
                  <Toast.Header closeButton={false} style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setName(teacher.name)
                      setSnils(teacher.snils)
                      setPhone(teacher.phone)
                      teacherStore.setSelectedTeacher(teacher)
                    }}>
                    <strong className="me-auto">{nameAppearenсe(teacher.name)}</strong>
                    <small>Курсы: {teacher.courses.length}</small>
                  </Toast.Header>
                  <Toast.Body>
                    <ul style={{ listStyle: 'none', paddingLeft: '1rem', marginBottom: '0.5rem' }}>
                      {teacher.courses.map(course => (
                        <li key={course.id}>{course.name}
                          <ul style={{ listStyle: 'circle', padding: '0 0 0 0.6rem' }}>
                            {course.streams.map(stream => (
                              <li key={stream.id}>{stream.name} </li>
                            )
                            )}
                          </ul>
                        </li>
                      )
                      )}
                    </ul>
                    <Button variant='outline-secondary' onClick={() => { setView({ ...view, [teacher.id]: !view[teacher.id] }) }}>{view[teacher.id] ? 'Свернуть' : 'Редактировать'}</Button>
                    <div className={view[teacher.id] ? 'd-block mt-2' : 'd-none'}>
                      <div className='d-flex w-100'>
                        <CourseStreamSelector selectHandler={csSelectHandler}/>
                      </div>
                      <div className='d-flex w-100'>
                        <Button variant='outline-warning' className='mt-2' onClick={() => { saveTeacherBinding(teacher.id) }}>Сохранить</Button>
                      </div>
                    </div>
                  </Toast.Body>
                </Toast>
              )
            })
          }
        </div>
      }

      <h4 className='mt-4' style={{ color: '#3c3c3c' }}>Создание, Поиск и Привязка преподавателя к ОУ </h4>
      <hr />

      <Form className='mt-4'>
        <Form.Group>
          <Form.Label style={{ color: "#22222250" }}> Телефон: </Form.Label>
          <Form.Control style={{ maxWidth: "320px" }} maxLength={11} value={phone} onChange={e => { setPhone(e.target.value) }} type="text" placeholder='телефон в формате 89123456789' />
        </Form.Group>
        <Form.Group>
          <Form.Label style={{ width: "auto", color: "#22222250", padding: "3px 2px", margin: "0" }}> СНИЛС: </Form.Label>
          <Form.Control style={{ width: "320px", maxWidth: "100%" }} maxLength={14} value={snils} onChange={e => { setSnils(e.target.value) }} type="text" placeholder='СНИЛС в формате 123-123-123 12' />
        </Form.Group>
        <Button className='mt-2' style={{ width: "128px" }} variant="outline-info" onClick={findTeacher}>Поиск</Button>
        <Form.Group>
          <Form.Label style={{ width: "auto", color: "#22222250", padding: "3px 2px", margin: "0" }}> ФИО: </Form.Label>
          <Form.Control style={{ width: "512px", maxWidth: "100%" }} value={name} onChange={e => { setName(e.target.value) }} type="text" placeholder='ФИО полностью в формате Иванов(а) Иван Иванович' />
        </Form.Group>
        {teacherStore.selectedTeacher &&
          <div className='mt-2'>
            <Button className='mt-2' style={{ width: "128px" }} variant="outline-success" onClick={CreateOrUpdateTeacher}>{teacherStore.selectedTeacher.id === null ? 'Внести в базу' : 'Обновить'} </Button>
            {teacherStore.selectedTeacher.id &&
            <div className='mt-2'>
              <Button className='mt-2' style={{ width: "128px" }} variant="outline-success" onClick={сhangeStatus}>{teacherStore.selectedTeacher.active ? 'Отключить' : 'Активировать'} </Button>
            </div>
            }
            {clientStore.selectedClient &&
              <div className={teacherStore.selectedTeacher.id ? 'd-block mt-2' : 'd-none'}>
                <Form.Label style={{ width: "auto", color: "#22222250", padding: "3px 2px", margin: "0 7px 0 0" }}> Привязать к учреждению "{clientStore.selectedClient.name}" с профилем: </Form.Label>
                <div className={clientStore.selectedClient.id ? 'd-flex w-100' : 'd-none'}>
                  <CourseStreamSelector />
                  <Button variant='outline-warning' className='ms-2' onClick={() => { saveTeacherBinding(teacherStore.selectedTeacher.id) }}>Добавить</Button>
                </div>
              </div>
            }
          </div>
        }



      </Form>

    </Container>
  )
})

export default TeacherConfig