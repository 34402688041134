import {makeAutoObservable} from "mobx"

export default class BindingStore {

    bindings = []
    selectedBinding = null

    constructor() {
        makeAutoObservable(this)
    }

    setDefaults() {
        this.bindings = []
        this.selectedBinding = null
    }

    setSelectedBinding(bindingIndex) {
        this.selectedBinding = this.bindings[bindingIndex]
    }

    setBindings(bindings) {
        this.bindings = bindings
    }


}